import React, {createContext, useState} from "react";
import logo from "./logo.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import Page from "./Components/Page";
import {FirebaseProvider} from './Components/Firebase'


//LogRocket Tracking
import LogRocket from 'logrocket';

LogRocket.init('lpespp/wtta');
// LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
//     name: 'James Morrison',
//     email: 'jamesmorrison@example.com',
//
//     // Add your own custom user variables here, ie:
//     subscriptionType: 'pro'
// });


function App() {
    const [data, setData] = React.useState(null);


    return (
        <FirebaseProvider>
            <Page/>
        </FirebaseProvider>
    );
}

export default App;
