import {useContext, useState} from "react";
import {FirebaseContext} from "./Firebase";
// import {signIn} from "./Firebase";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


const Login = () => {

    const {signIn} = useContext(FirebaseContext)

    const LoginFunc = (event) => {
        event.preventDefault()
        // console.log(password)
        signIn(email, password)
    }

    const [email, set_email] = useState('')
    const [password, set_password] = useState('')

    return (<div>
        <h1>Log into WTTA</h1>
        <form className={'d-div p-2"'}>
            <p>
                <label>eMail: </label>
                <input key={'email'} type={'text'} value={email} onChange={e => set_email(e.target.value)}/>
            </p>
            <p>
                <label>Password: </label>
                <input key={'password'} type={'password'} value={password}
                       onChange={e => set_password(e.target.value)}/>
            </p>
            <button onClick={LoginFunc}>Login</button>
            {/*<Link to={'/register'}>*/}
            {/*    <button>Register Account</button>*/}
            {/*</Link>*/}
        </form>
    </div>)
}

export default Login