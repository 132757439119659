import {Navbar, Nav, FormControl, Form, Button, NavDropdown} from "react-bootstrap"
import {Link} from 'react-router-dom';
import {useContext} from "react";
import {FirebaseContext} from "./Firebase";

// import {signOut, authHelper} from "../Components/Firebase";


function Header() {
    const {user, logOut, envName, permissions} = useContext(FirebaseContext)

    return (<>
        {envName.includes('dev1') &&
            <div style={{background: 'red', textAlign: 'center'}}><b>!!!!! - This is a test ENV - !!!!!</b></div>}
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand as={Link} to="/">We Turn Tix Analytics</Navbar.Brand>
            {user && <Nav className="mr-auto">
                <div></div>
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                {permissions.tmtracker && <NavDropdown title={'TicketMaster'} className={'bg-dark'}>
                    <NavDropdown.Item as={Link} to="/tm/0.001/EventFinder">Event Finder</NavDropdown.Item>
                    <NavDropdown.Item as={Link} disabled={true} to="/tm/0.001/MyEvents">My Events</NavDropdown.Item>
                    <NavDropdown.Item as={Link} disabled={true} to="/tm/0.001/MyEvents">Alerts</NavDropdown.Item>
                </NavDropdown>}
                {permissions.tdcc && <Nav.Link as={Link} to="/tdc">Tickets.com</Nav.Link>}
                {permissions.tdcc_codes && <Nav.Link as={Link} to="/tdcfinder">Tickets.com - Finder</Nav.Link>}
                {permissions.ticketEvoBulkPurchase && <NavDropdown title={'TicketEvo'} className={'bg-dark'}>
                    <NavDropdown.Item as={Link} to="/ticketevo/bulkbuyer">Bulk Buyer</NavDropdown.Item>
                </NavDropdown>}
                <NavDropdown title={'Account'} className={'bg-dark'}>
                    <NavDropdown.Item as={Link} to="/settings">Settings</NavDropdown.Item>
                    <NavDropdown.Item
                        onClick={() => logOut().then(() => window.location.reload())}>LogOut</NavDropdown.Item>
                </NavDropdown>
                {permissions.admin && <NavDropdown title={'Admin'} className={'bg-dark'} bg="dark" variant="dark">
                    <NavDropdown.Item as={Link} to="/admin/users">Users</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/health">Health & Stats</NavDropdown.Item>
                </NavDropdown>}
                <Navbar.Text>v.2022-03-28:1</Navbar.Text>
            </Nav>}
        </Navbar>
    </>)
}

export default Header