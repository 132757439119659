import firebase from 'firebase/compat/app';
// import 'firebase/compat/';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import {getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {createContext, useEffect, useState, useMemo, useContext} from "react";
import {deleteCookie, setCookie} from "../Helpers/cookies";
import {useHistory} from "react-router-dom";

// https://firebase.google.com/docs/web/setup#available-libraries

const originhost = document.location.origin // 'http://localhost:3000'
// console.log(originhost)
const envName = (['http://localhost:3000', 'http://localhost:5001', 'https://wtta-dev1-e7a9d.web.app'].includes(originhost)) ? "wtta-dev1-e7a9d" : "wtta-main"

// Your web app's Firebase configuration
const firebaseConfig = {
    "wtta-dev1-e7a9d": {
        apiKey: "AIzaSyCiJtFwObS1stZ5X252Tclyo9x-4IQrzrQ",
        authDomain: "wtta-dev1-e7a9d.firebaseapp.com",
        databaseURL: "https://wtta-dev1-e7a9d-default-rtdb.firebaseio.com",
        projectId: "wtta-dev1-e7a9d",
        storageBucket: "wtta-dev1-e7a9d.appspot.com",
        messagingSenderId: "726136340453",
        appId: "1:726136340453:web:74b1c1ba546c65af47790c"
    },
    "wtta-main": {
        apiKey: "AIzaSyDIPkrLa3r1fdryvDPVc5voy6BoFHAHcqE",
        authDomain: "wtta-main.firebaseapp.com",
        projectId: "wtta-main",
        storageBucket: "wtta-main.appspot.com",
        messagingSenderId: "875993358885",
        appId: "1:875993358885:web:961081606d2f55c7d455cc"
    }
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig[envName]);

console.log(document.location.origin)

const authHelper = firebase.auth();
const funcHelper = firebase.functions()
if (['http://localhost:3000', 'http://localhost:5001'].includes(originhost)) {
    const localhost = "localhost"
    funcHelper && funcHelper.useEmulator(localhost, 5002)
    authHelper && authHelper.useEmulator('http://localhost:9099')
}

export const FirebaseContext = createContext()
export const useFirebaseContext = () => useContext(FirebaseContext)

export const FirebaseProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState({})
    // const history = useHistory()

    useEffect(() => {
        if (user && Object.keys(permissions).length === 0) {
            const getUser = funcHelper.httpsCallable('api_fetchUser')
            getUser().then(data => {
                // console.log('get permissions', data.data.permissions)
                setPermissions(data.data.permissions)
                // alert('eee')
            })
        }
    }, [user])

    authHelper.onAuthStateChanged(user => {
        const cookieName = '__session'
        const accessToken = user && user.multiFactor.user && user.multiFactor.user.accessToken
        user ? setCookie(cookieName, accessToken, 1) : deleteCookie(cookieName)
        setUser(user)
    });

    useEffect(() => {

    }, []);

    authHelper.onIdTokenChanged(user => {
        if (user) {
            // console.log(user)
            user.getIdToken().then(token => {
                // console.log('onIdTokenChanged', user)
                // document.cookie = `__session=${user.email};max-age=3600`;
                // document.cookie = `__session=${token};max-age=3600`;
            });
        } else {
            // console.log('onIdTokenChanged', user)
            // document.cookie = '__session=;max-age=0';
        }
    });


    return (
        <FirebaseContext.Provider value={{
            envName,
            user,
            permissions,
            firebase,
            functions: funcHelper,
            // getSessionToken: funcHelper.httpsCallable('getSessionToken'),
            getFunction: functionName => funcHelper.httpsCallable(functionName),
            signIn: (email, password) => {
                return authHelper.signInWithEmailAndPassword(email, password)
                    .then((user) => {
                        window.location.replace('/')
                    })
                    .catch((error) => {
                        alert(`Login Error: ${error.code} - ${error.message}`)
                        console.log('Login Error', error)
                        const errorCode = error.code;
                        const errorMessage = error.message;
                    });
            },
            logOut: () => authHelper.signOut(),
            register: (email, password) => authHelper.createUserWithEmailAndPassword(email, password),
        }}>{children}</FirebaseContext.Provider>
    );
};


const signIn = (email, password) => {
    return signInWithEmailAndPassword(authHelper, email, password)
        .then((userCredential) => {
            // Signed in
            // alert('ezra')
            const user = userCredential.user;
            // ...
        })
        .catch((error) => {
            alert(error.code)
            const errorCode = error.code;
            const errorMessage = error.message;
        });
}

// const logOut = () => {
//     signOut(authHelper).then(() => {
//         // Sign-out successful.
//     }).catch((error) => {
//         // An error happened.
//     });
// }

// export  {app, authHelper, signIn, signOut}