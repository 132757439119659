import {Container, Row, Col} from "react-bootstrap"
import Header from "./Header";
import Listings from "./../TicketMasterTracker/Listings";
import DiscountFinder from "./../toolsTCT/discountFinder";
import TicketEvo from "./../TicketEvo/index";

import React, {useContext, lazy, Suspense} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, Redirect
} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import {useFirebaseContext} from "./Firebase";
import {deleteCookie, setCookie} from "../Helpers/cookies";

function Page({children}) {
    const FirebaseContext = useFirebaseContext()
    console.log(FirebaseContext.user)
    /*
    keeps failing, after loggin in, reload the expected script multiple times until ready.
    https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
     */

    function retry(fn, retriesLeft = 5, interval = 1000) {
        return new Promise((resolve, reject) => {
            fn()
                .then(resolve)
                .catch((error) => {
                    setTimeout(() => {
                        if (retriesLeft === 1) {
                            // reject('maximum retries exceeded');
                            reject(error);
                            return;
                        }

                        // Passing on "reject" is the important part
                        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                    }, interval);
                });
        });
    }

    const AdminRouting = FirebaseContext.user ? lazy(() => retry(() => {
        // // console.log(FirebaseContext.user)
        // // console.log(FirebaseContext.user.multiFactor.user)
        // setCookie(cookieName, FirebaseContext.user.multiFactor.user.accessToken)
        // TODO: make it so that there's a fallback if there user is not logged in.
        const returnCode = import(/* webpackChunkName: "AdminRouting" */ './AdminRouting.jsx')
        return returnCode
    })):'div';

    return (
        <>
            <Router>
                <Container className="wtta_Container" fluid>
                    <Row fluid>
                        <Header/>
                    </Row>
                    {FirebaseContext.user ?
                        <Switch>
                            <Route exact path={'/'}>Welcome {FirebaseContext.user.email}...</Route>
                            <Suspense fallback={null}>
                                <AdminRouting />
                            </Suspense>
                            <Route path={'/logout'} component={() => {FirebaseContext.logOut()}} />
                        </Switch>
                        :
                        <Switch>
                            {/*<Route path={'/register'} component={Register} />*/}
                            <Route path={'/login'} component={Login} />
                            <Route exact path={'/'} component={Login} />
                            {/*{FirebaseContext.user === null && document.location && !['/login', '/register'].includes(document.location.pathname) && <Redirect to={'/login'} />}*/}
                        </Switch>

                    }
                </Container>
            </Router>
        </>
    )
}

export default Page