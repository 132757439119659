import React, {useEffect, useState} from "react";
import {Accordion, Spinner} from "react-bootstrap"
import {useFirebaseContext} from "../Components/Firebase";
import moment from "moment";

// React.useEffect(() => {
//     fetch("/api")
//         .then((res) => res.json())
//         .then((data) => setData(data.message));
// }, []);


function DiscountFinder() {
    const {getFunction, permissions} = useFirebaseContext()
    const getDiscounts = getFunction('api_tools_tdc')
    // alert('eee')
    // const [tdcURL, setTDcURL] = React.useState((new URL(document.URL)).searchParams.get('u'))
    const [tdcURL, setTDcURL] = React.useState('')
    const [preFillURL, set_preFillURL] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({}) //React.useState({eventName: 'ABC', coupons: [{code: '123'}]});
    const [goSearch, setGoSearch] = React.useState(false)

    // console.log(data && data.rawJSON && JSON.parse(data.rawJSON.stringValue).event['_attributes']['public_desc'])

    React.useEffect(() => {

        const urlObj = new URL(document.location)
        const eventURLEscaped = urlObj.searchParams.get('eventURL')
        if(eventURLEscaped) {
            const eventURL = decodeURIComponent(eventURLEscaped)
            setTDcURL(eventURL)
            setGoSearch(true)
        }

        if (goSearch === true && loading === false) {
            setLoading(true)

            const urlInBase64 = `/api_tmc?u=${Buffer.from(tdcURL).toString('base64')}`

            getDiscounts({permissions, url: `${Buffer.from(tdcURL).toString('base64')}`}).then(res => {

                setGoSearch(false)
                setData(res.data)
                setLoading(false)
            })

        }
    }, [goSearch]);

    const search = e => {
        e.preventDefault();
        const ParsedURL = new URL(tdcURL)
        //https://mpv.tickets.com/?agency=MLB_MPV&orgid=31&pid=8865417#/event/8865417/seatmap/?selectBuyers=false&minPrice=9&maxPrice=176&quantity=2&sort=price_desc&ada=false&seatSelection=true&onlyCoupon=true&onlyVoucher=false
        const pid = ParsedURL.searchParams.get('pid')
        const agency = ParsedURL.searchParams.get('agency')
        const orgid = ParsedURL.searchParams.get('orgid') || ParsedURL.searchParams.get('orgId')
        // console.log(tdcURL.startsWith('https://mpv.tickets.com') , orgid , agency , pid)
        if (tdcURL.toLowerCase().startsWith('https://mpv.tickets.com')) {
            setGoSearch(true)
        } else {
            alert('This search only works with Tickets.com')
        }
    }

    // const showSavings = buyerID => {
    //     // console.log(buyerID)
    //     const savingsArea = data.tdc_events_price_structure.filter(r => r.buyer_typeID === buyerID)
    //
    //     // console.log(savingsArea[0]['pricescale_id'])
    //     console.log(`Normal Price: ${data.tdc_events_pricescales[savingsArea[0]['pricescale_id']]['ref_price']}`)
    //     console.log(`Coupon Price : ${savingsArea[0]['price']}`)
    //     return (
    //         <>sdsdsd</>
    //     )
    // }


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const firstTabToOpen = permissions.tdcc_codes === true ? "0":"1"

    return (<>
            <h1>Tickets.com</h1>
            <form onSubmit={search}>
                <label>Please enter the event URL:</label>
                <input type={'text'} id={'tdcURL'} name={'tdcURL'} value={tdcURL} onChange={e => {
                    setTDcURL(e.target.value)
                }}/>
                <button type={'submit'} disabled={goSearch}>Search</button>
            </form>
            <hr/>
            {loading && <Spinner animation="border" role="status">
                {/*<span className="visually-hidden">Loading...</span>*/}
            </Spinner>}
            {/*{!data && tdcURL !== null && tdcURL.startsWith('aHR0cHM6Ly9tcHYudGlja2V0cy5jb20') && <>Loading...</>}*/}
            {/*{!data && (tdcURL === null || !tdcURL.startsWith('aHR0cHM6Ly9tcHYudGlja2V0cy5jb20')) && <>Unable to load event...</>}*/}

            {/*{console.log(data.tdc.c)}*/}
            {tdcURL && <><a href={tdcURL} target={'_blank'}>Link to Event</a><hr/></>}
            <Accordion defaultActiveKey={firstTabToOpen}>
                {permissions.tdcc && permissions.tdcc_codes && data && data.tdc && Object.values(data.tdc.c).length !== 0 &&
                    <Accordion.Item eventKey={"0"}>
                        <Accordion.Header>Codes</Accordion.Header>
                        <Accordion.Body>
                            {Object.values(data.tdc.c).map(row => {
                                const ParsedURL = new URL(tdcURL)
                                const pid = ParsedURL.searchParams.get('pid')
                                const agency = ParsedURL.searchParams.get('agency')
                                const orgid = ParsedURL.searchParams.get('orgid') || ParsedURL.searchParams.get('orgId')

                                const codeURL = `${ParsedURL.origin}?agency=${agency}&orgid=${orgid}&pid=${pid}&coupon=${row.code}`

                                return <p><h4><b>{row.desc}</b>: <a
                                    href={codeURL}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {row.code}
                                </a>
                                </h4>
                                    <p>{Object.values(row.costCompare).map(rrr => {
                                        return <p>{rrr.sectionName} - Reg: ${rrr.regularPrice} - Code:
                                            ${rrr.couponPrice} = ${rrr.priceDiff}</p>
                                    })}</p>
                                </p>
                            })}</Accordion.Body>
                    </Accordion.Item>
                }

                {permissions && permissions.tdcc_inventory && data.tdc && data.tdc.i && <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>Inventory</Accordion.Header>
                    <Accordion.Body>
                        {Object.values(data.tdc.i).map(row => {
                            // console.log(row)
                            const calcPercentage = (a,b) => {
                                if((a/b) === NaN) {
                                    return 0
                                }
                                if(b === 0 || a === 0) return 0
                                return parseFloat((a/b)*100).toFixed(2)
                            }
                            return <p>{row.sectionName} - Capacity: {row.capacity} - Available: {row.available} = {calcPercentage(row.available,row.capacity)}%</p>
                        })}
                    </Accordion.Body>
                </Accordion.Item>
                }

            </Accordion>

            {/*//Old View*/}
            {/*{data && <>*/}
            {/*    <h1>{data.eventName}</h1>*/}
            {/*    /!*{console.log(data.meta.metaFetchedTime.hours)}*!/*/}
            {/*    {1 && data.tdc_coupons && <>*/}
            {/*        /!*<>{data.meta.metaFetchedTime.hours} hours and {data.meta.metaFetchedTime.minutes} minutes ago.</><br/><br/>*!/*/}
            {/*        <ul>*/}
            {/*            /!*{console.log(data.tdc_coupons)}*!/*/}
            {/*            {data.tdc_coupons.map(row => {*/}
            {/*                if (row.code) {*/}
            {/*                    return (*/}
            {/*                        <li key={row.code}>*/}
            {/*                            <b><a href={row.eventURL} target={'_blank'}>{row.code}</a> - {row.promotion_desc}</b> <br/> {row.prices && <table>*/}
            {/*                            <tr>*/}
            {/*                                <th>Description</th>*/}
            {/*                                <th>Normal Price</th>*/}
            {/*                                <th>Coupon Price</th>*/}
            {/*                                <th>Difference Price</th>*/}
            {/*                            </tr>*/}
            {/*                            {row.prices.map(r => {*/}
            {/*                                // console.log(r)*/}
            {/*                                return <tr>*/}
            {/*                                    <td>{r.desc}</td>*/}
            {/*                                    <td>{formatter.format(r.normalPrice)}</td>*/}
            {/*                                    <td>{formatter.format(r.couponPrice)}</td>*/}
            {/*                                    <td>{formatter.format(r.differencePrice)}</td>*/}
            {/*                                </tr>*/}

            {/*                            })}*/}
            {/*                        </table>}*/}
            {/*                            /!*<a target={'_blank'} href={'https://www.google.com'}>Open in new Tab</a>*!/*/}
            {/*                        </li>*/}
            {/*                    )*/}
            {/*                }*/}
            {/*            })}*/}
            {/*        </ul>*/}
            {/*    </>}*/}

            {/*</>}*/}
        </>

    )
}

function Finder() {
    const {getFunction, permissions} = useFirebaseContext()
    const tdc_finder = getFunction('tdc_finder')

    const [data, setData] = useState([])

    useEffect(() => {
        tdc_finder().then(d => {
            // console.log(d.data)
            setData(d.data)
        })
    },[])

    console.log(window.location)
    return (<>
    <h1>Event Finder</h1>
        <hr/>
        {data && true && data.map(row => {
            // console.log(row)
            const tdcEventURL = `https://mpv.tickets.com/?agency=${row.agency.toUpperCase()}&orgid=${row.orgid}&pid=${row.id}`
            // console.log(tdcEventURL)
            const wttaEventURL = `${window.location.protocol}//${window.location.host}/tdc?eventURL=${encodeURIComponent(tdcEventURL)}`
            return <p>
                <a href={wttaEventURL} target={'_blank'}><b>{row.description}</b> - {moment(row.eventDate).format('MM-DD-YYYY')}{row.couponsExists && ' - +'}</a>
            </p>
        })}
    </>)
}

export  {DiscountFinder, Finder}