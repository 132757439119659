import React, {useEffect, useState} from "react";
import moment from "moment";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import {useTable} from 'react-table'
import {useFirebaseContext} from "../Components/Firebase";

const currencyFormat = (num) => {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const TicketEvo = (props) => {
    const firebaseContext = useFirebaseContext()
    const ticketEvoEndPoint = firebaseContext.getFunction('api_tools_te')
    const [data, setData] = useState({})
    const [filters, setFilters] = useState({})
    const [cartTotal, setCartTotal] = useState('')
    const [seatQtyTotal, setSeatQtyTotal] = useState(0)
    const [orderSummary, setOrderSummary] = useState({})
    const {eventID} = props.match.params
    const currentURL = new URL(window.location)
    const originHost = (currentURL.origin === 'http://localhost:3000') ? 'http://localhost:2001' : currentURL.origin
    const coreHost = (currentURL.origin === 'https://wtta-main.weturntix.com') ? 'https://core.ticketevolution.com' : 'https://core.sandbox.ticketevolution.com'

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        window.location.reload(false);
    };
    const handleShow = () => setShow(true);


    useEffect(() => {
        ticketEvoEndPoint({action: 'loadEvent', eventID: eventID}).then(response => {
            // console.log(response.data)
            return response.data
        }).then(response => {
            // console.log(response.inventoryDetails)
            response.inventoryDetails.ticket_groups = response.inventoryDetails.ticket_groups.sort((a, b) => a.wholesale_price - b.wholesale_price)
            response.inventoryDetails.ticket_groups = response.inventoryDetails.ticket_groups.map(row => {
                return {...row, wtta_addToCart: false}
            })
            setData(response)
            // console.log()
        })
    }, [])

    useEffect(() => {
        // console.log(data.inventoryDetails && data.inventoryDetails.ticket_groups[0])
        let totalCartValue = 0
        let seatQty = 0
        if (data.inventoryDetails) {
            data.inventoryDetails.ticket_groups.forEach(row => {
                if (row.wtta_addToCart === true) {
                    // console.log(row.available_quantity)
                    // console.log(row.wholesale_price)
                    totalCartValue = totalCartValue + (row.available_quantity * row.wholesale_price)
                    seatQty = seatQty + row.available_quantity
                }
            })
            setCartTotal("$ " + (Math.round(totalCartValue * 100) / 100).toLocaleString())
            setSeatQtyTotal(seatQty)
        }
    }, [data])

    const updateAddToCart = groupID => {
        const dupData = {...data}
        const updateIndexID = Object.keys(dupData.inventoryDetails.ticket_groups).find(row => {
            // console.log(row)
            return dupData.inventoryDetails.ticket_groups[row].id === groupID
        })
        dupData.inventoryDetails.ticket_groups[updateIndexID].wtta_addToCart = !dupData.inventoryDetails.ticket_groups[updateIndexID].wtta_addToCart
        setData(dupData)
    }


    const tableRow = (rowData, id) => {

        const {
            available_quantity,
            section,
            row,
            wholesale_price,
            retail_price,
            office,
            format,
            wtta_addToCart
        } = rowData
        // console.log('eee', rowData)
        return (
            <tr style={{}}>
                <td><input type="checkbox" id="vehicle1" name="vehicle1" defaultChecked={wtta_addToCart}
                           checked={wtta_addToCart}
                           onChange={e => updateAddToCart(rowData.id)}
                           disabled={office.brokerage.abbreviation === 'WE TURN TIX'} value="Bike"/></td>
                <td>{available_quantity}</td>
                <td>{section}</td>
                <td>{row}</td>
                <td></td>
                <td>{currencyFormat(wholesale_price)}</td>
                <td>{currencyFormat(retail_price)}</td>
                <td>{office.brokerage.abbreviation}</td>
                <td>{format}</td>
            </tr>
        )
    }

    const updateFilter = (e, id) => {
        // console.log(e, id)
    }

    const buyTickets = () => {
        if (1 || window.confirm(`Buying ${cartTotal} worth of tickets....`)) {
            // fetch('')

            const purchaseOrder = {
                eventID,
                ticket_groups: data.inventoryDetails.ticket_groups.filter(row => row.wtta_addToCart).map(row => row.id)
            }
            // console.log(purchaseOrder)
            ticketEvoEndPoint({action: 'purchaseTickets', purchaseOrder}).then(data => {
                // console.log(data.data.responses)
                // console.log(data.responses[0].value)

                setOrderSummary(data.data.responses)
                setShow(true)

                // window.alert('ds' + data.responses[0].value.map(row => {
                //     return row.value.orders[0].id + '\n'
                // }))
            })
        } else {
            alert('not buying')
        }
    }

    const OrderSumDetail = () => {
        // console.log('aaa', orderSummary)
        return (
            <>
                {1 && orderSummary && orderSummary[0] && orderSummary.map(row => {
                    const rowOrderObj = row.value
                    // console.log(rowOrderObj.success)
                    if (rowOrderObj.error) {
                        return (<p> - Error with purchase... {rowOrderObj.error}</p>)
                    } else if (rowOrderObj.success) {
                        const purchaseObj = rowOrderObj.success.orders[0]
                        const linkURL = `${coreHost}/order/${purchaseObj.id}`
                        return (
                            <>
                                <p>Order: {purchaseObj.oid}, Total: {currencyFormat(purchaseObj.total)}, <a
                                    href={linkURL} target={'_blank'}>Link</a></p>
                            </>)

                    }
                })}
            </>
        )
    }


    return (!data.eventDetails && (<>Waiting for data...</>)) || (
        <div>
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Purchase Summary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <OrderSumDetail/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {/*<Button variant="primary">Open Orders</Button>*/}
                    </Modal.Footer>
                </Modal>
            </>
            <h1>{data.eventDetails.name}</h1>
            <h1>{moment(data.eventDetails.occurs_at_local).format('YYYY-MM-DD @ HH:MM')}</h1>
            <p>{data.eventDetails.stubhub_id &&
            <a href={`https://www.stubhub.com/event/${data.eventDetails.stubhub_id}`} target={'_blank'}>Link to
                Stubhub</a>}</p>
            {/*{console.log(cartTotal === '$ 0')}*/}
            <p>Cart Total: {cartTotal}, Qty: {seatQtyTotal}</p>
            <button disabled={cartTotal === '$ 0'} onClick={buyTickets}>Buy Now!</button>
            <div>
                {/*<b>Filters</b>*/}
                {/*<div>*/}
                {/*    <span>Price Less Than:</span>*/}
                {/*    <input key={'priceLessThan'} onChange={e => updateFilter(e.target.value, 'qqqq')}/>*/}
                {/*</div>*/}
            </div>
            <table style={{
                minWidth: 800,
                borderCollapse: 'separate',
                borderSpacing: '0px px'
            }}>
                <thead>
                <tr>
                    <th>Check</th>
                    <th>Qty</th>
                    <th>Sec</th>
                    <th>Row</th>
                    <th>Seats</th>
                    <th>Wholesale</th>
                    <th>Retail</th>
                    <th>Broker</th>
                    <th>Ticket Type</th>
                </tr>
                </thead>
                <tbody>
                {data.inventoryDetails.ticket_groups && data.inventoryDetails.ticket_groups.map(tableRow)}
                </tbody>
            </table>
        </div>
    )

}


export default TicketEvo